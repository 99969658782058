var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "PasswordRecoverCode" },
    [
      _c("TransitionFade", [
        _vm.validCode
          ? _c(
              "div",
              { staticClass: "form-panel" },
              [
                _c("TransitionSlide", { attrs: { side: "right" } }, [
                  _c(
                    "form",
                    {
                      staticClass: "Register",
                      on: {
                        input: function($event) {
                          return _vm.validation($event)
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "form-body" },
                        [
                          _c("h1", { staticClass: "title" }, [
                            _vm._v(
                              " " + _vm._s(_vm.$t("NEW_PASSWORD.TITLE")) + " "
                            )
                          ]),
                          _vm._l(_vm.fields, function(field, i) {
                            return _c(
                              "BaseInput",
                              _vm._b(
                                {
                                  key: i,
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "error",
                                        fn: function() {
                                          return [
                                            _c("ValidationError", {
                                              attrs: {
                                                errors: _vm.errors,
                                                name: field.name
                                              }
                                            })
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    true
                                  ),
                                  model: {
                                    value: field.value,
                                    callback: function($$v) {
                                      _vm.$set(field, "value", $$v)
                                    },
                                    expression: "field.value"
                                  }
                                },
                                "BaseInput",
                                field,
                                false
                              )
                            )
                          })
                        ],
                        2
                      ),
                      _c(
                        "div",
                        { staticClass: "form-footer" },
                        [
                          _c(
                            "BaseButton",
                            {
                              staticClass: "register-btn",
                              attrs: { size: "l", variation: "primary" },
                              on: { click: _vm.submit }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("SEND.BUTTON")) + " ")]
                          )
                        ],
                        1
                      )
                    ]
                  )
                ])
              ],
              1
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }