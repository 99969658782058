import { render, staticRenderFns } from "./PasswordRecoverCode.vue?vue&type=template&id=2cd70a8c&scoped=true&"
import script from "./PasswordRecoverCode.vue?vue&type=script&lang=js&"
export * from "./PasswordRecoverCode.vue?vue&type=script&lang=js&"
import style0 from "./PasswordRecoverCode.vue?vue&type=style&index=0&id=2cd70a8c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2cd70a8c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\Guillermo\\Proyectos\\icc_front\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2cd70a8c')) {
      api.createRecord('2cd70a8c', component.options)
    } else {
      api.reload('2cd70a8c', component.options)
    }
    module.hot.accept("./PasswordRecoverCode.vue?vue&type=template&id=2cd70a8c&scoped=true&", function () {
      api.rerender('2cd70a8c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/PasswordRecoverCode.vue"
export default component.exports